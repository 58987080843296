@use 'sass:math';

.s-content__news {
  position: relative;

  @include media-breakpoint-up(lg) {
    .news-wrap {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .read-all {
    position: absolute;
    right: $grid-gutter-width;
    top: -$grid-gutter-width;
    color: $brand-primary;
  }

  .related-files li {
    word-break: break-all;
  }

  @include media-breakpoint-up(xl) {
    &.overview {
      .s-content__news-item {
        //@include first(3) {
        //	margin-bottom: $grid-gutter-width / 2;
        //	position: relative;
        //	&:after {
        //		content: '';
        //		position: absolute;
        //		bottom: -($grid-gutter-width / 2);
        //		left: 0;
        //		width: calc(100% + 1px);
        //		height: 1px;
        //		background: $gray-light;
        //	}
        //
        //
        //}
        //
        //@include after-first(3) {
        //	margin-top: $grid-gutter-width / 2;
        //	margin-bottom: $grid-gutter-width / 2;
        //}
        //
        //@include n-between(1, 4, 999) {
        //	position: relative;
        //	&:after {
        //		content: '';
        //		position: absolute;
        //		bottom: -($grid-gutter-width / 2);
        //		left: 0;
        //		width: calc(100% + 1px);
        //		height: 1px;
        //		background: $gray-light;
        //	}
        //}
      }
    }
  }

  figure {
    overflow: hidden;
    position: relative;
    margin: 0;

    @include media-breakpoint-down(md) {
      img {
        width: 100%;
        height: auto;
      }
    }

    figcaption {
      position: absolute;
      bottom: 0;
      line-height: normal;
      font-size: px-to-rem(14);
      left: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      padding: math.div($grid-gutter-width, 2);

      transition: opacity 0.5s ease-in-out;
      opacity: 0;
    }

    img {
      // transition: all .3s ease-in-out;
    }

    &:hover {
      figcaption {
        opacity: 1;
      }

      /* fancy spielerei ;) fällts wem auf? ;) */
      img {
        // transform: 	scale(1.02)
        // rotate(-2deg);
      }
    }
  }

  .s-content__news-item {
    @extend .col-sm-12;
    //figure {display:none;}

    @include media-breakpoint-up(lg) {
      margin-right: 33.3%;
    }
    //@extend .col-xl-3;
    //@extend .col-lg-6;

    @include media-breakpoint-up(xl) {
      //border-left: 1px solid $gray-light;

      &:nth-of-type(1) {
        border-left: none;
      }

      &.bordered:nth-child(4n) {
        border-left: none;
      }
    }

    padding-top: math.div($grid-gutter-width, 2);
    padding-bottom: math.div($grid-gutter-width, 2);

    a + div header {
      margin-top: math.div($grid-gutter-width, 2);
    }

    time {
      font-size: px-to-rem(14);
    }

    h3 {
      @extend .h1;
      margin: math.div($grid-gutter-width, 2) 0;
      hyphens: auto;

      a {
        color: $brand-primary;
        text-decoration: none;

        &:hover {
          color: $brand-primary;
        }
      }
    }

    &.topnews {
      @extend .col-xs-12;

      h3 {
        margin: math.div($grid-gutter-width, 2) 0;

        a {
          color: $brand-primary;
          text-decoration: none;

          &:hover {
            color: $brand-primary;
          }
        }
      }
    }

    &:not(:first-of-type) {
      figure {
        img {
          @include media-breakpoint-up(lg) {
            width: 100%;
            height: auto;
          }
          @include media-breakpoint-up(xl) {
            //margin-left: -50%;
          }
        }
      }
    }

    .read-more {
      color: $brand-primary;
    }

    .no-image-placeholder {
      height: 390px;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .s-content__news-detail {
    h2 {
      @extend h1;
    }

    .news-backlink-wrap {
      margin: math.div($grid-gutter-width, 2) 0;
    }

    .news-img-wrap {
      margin-bottom: $grid-gutter-width;
    }

    .teaser-text {
      @extend h3;
      line-height: 1.2;
      color: $brand-primary;
    }

    figure {
      display: inline-block;
    }

    & + aside {
      padding-top: $grid-gutter-width * 2;
      padding-bottom: $grid-gutter-width;

      h4 {
        text-transform: uppercase;
        color: $brand-primary;
        font-size: px-to-rem(14);
        line-height: 1.3;
        border-bottom: 1px solid $brand-primary;
        padding: 0 math.div($grid-gutter-width, 4) math.div($grid-gutter-width, 4) math.div($grid-gutter-width, 4);
        margin-bottom: math.div($grid-gutter-width, 4);

        @include after-first(1) {
          margin-top: $grid-gutter-width * 2;
        }
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          position: relative;
          padding-left: math.div($grid-gutter-width, 1.5);

          &:before {
            content: '•';
            color: $theme-color-1;
            position: absolute;
            left: 5px;
          }
        }

        &.related-news {
          margin: 0;
          padding: 0;

          li {
            padding: 0;
            list-style: none;

            &:before {
              display: none;
            }
          }
        }
      }

      .news-related-files-size {
        padding: 0 math.div($grid-gutter-width, 4);
        color: $theme-color-2;
        opacity: 0.7;
        font-size: 0.85rem;
      }

      .read-more {
        color: $brand-primary;
        display: block;
        position: relative;
        margin: math.div($grid-gutter-width, 8) 0;
      }
    }
  }

  .page-navigation {
    margin-top: $grid-gutter-width * 2;

    p {
      display: none;
    }

    ul {
      background: $theme-color-3;
      margin: 0;
      padding: math.div($grid-gutter-width, 4);
      list-style: none;

      li {
        display: inline-block;
        margin-right: 4px;
        border: 1px solid $brand-primary;
        line-height: $grid-gutter-width * 1.5;
        height: $grid-gutter-width * 1.5;
        width: $grid-gutter-width * 1.5;
        text-align: center;

        &.current {
          background: $brand-primary;
          color: #fff;
        }

        a {
          display: block;
          text-decoration: none;
        }

        &.next,
        &.previous {
          position: relative;
          text-indent: -9999px;
          a:after {
            @include inline-svg($nav-arrow);
            background-size: 10px;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: $grid-gutter-width * 1.5;
            height: $grid-gutter-width * 1.5;
          }
        }

        &.previous {
          transform: rotate(180deg);
          text-indent: 999px;
        }
      }
    }
  }
}

.frame-type-list {
  header {
    margin-bottom: $grid-gutter-width;
  }
}

.news-card {
  font-size: 14px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }

  &__image {
    aspect-ratio: math.div(3, 2);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__heading {
    h3 {
      font-weight: 400;
      font-size: 26px;
      text-wrap: balance;
      hyphens: auto;

      @include media-breakpoint-up(md) {
        font-size: 35px;
      }
    }
  }

  a {
    color: $brand-primary;
  }
}
