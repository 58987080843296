p.lead {
  color: $ce-color-lead;
  @include font-smoothing();
  font-weight: 100;
  line-height: $lead-line-height;
  margin-bottom: px-to-rem(54);
}

.ce-lead {
}
