html {
  @include media-breakpoint-down(xs) {
    font-size: 14px;
  }
}

*,
html {
  font-weight: 100;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 300;
}
strong {
  font-weight: 600;
}

h1 {
  color: $brand-primary;
  margin-bottom: px-to-rem(23);
}
h2 {
  color: $brand-primary;
  .content-main & {
    margin-top: px-to-rem(43);
    margin-bottom: px-to-rem(20);

    font-weight: 100;

    a {
      text-decoration: none !important;
    }
  }
}
h3 {
  color: $link-color;
  .content-main & {
    margin-top: px-to-rem(30);
    margin-bottom: px-to-rem(20);

    font-weight: 100;
  }
}

.text {
  /**
	  * Add breakpoint independently text align classes
	  */
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
  &-center {
    text-align: center;
  }
}

a {
  &.block-link {
    color: inherit;
    display: block;
  }
}
