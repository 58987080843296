.s-logobar {
  background: $brand-alternative;
  min-height: $logobarHeight;
  height: auto;

  .container .row > div {
    padding-left: 0;
    padding-right: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;

    li {
      display: inline-block;
      line-height: $logobarHeight;

      // margin-left: $grid-gutter-width / 4;
      // margin-right: $grid-gutter-width / 4;

      img {
        height: 102px;
        width: auto;
      }
    }
  }
}
