$supi-colors: (
  background: $main-background-color,
  primary: $brand-primary,
  secondary: $brand-primary,
  button-bar: $brand-secondary,
  success: $brand-primary,
  warning: $main-background-color,
  notice: $main-background-color,
  button-wrap-bgcolor: $main-background-color,
  underline: lighten($brand-light, 20%),
);

$supi-border: false;
$supi-banner-box-border: 0;
$supi-block-margin: 15px;

@import '../../../../../../vendor/supseven/supi/Build/Src/Scss/Supi';

.tx-supi {
  button {
    &#supi__save,
    &#supi__dismiss,
    &#supi__individualSwitchTo {
      background: var(--supi-color-allow-background);
      color: var(--supi-color-allow);
    }
  }

  .tx-supi {
    &__block {
      .tx-supi__block-header {
        padding-bottom: ($supi-block-margin * 0.8);
      }

      ul {
        margin-bottom: 0;
      }
    }

    &__block-item {
      margin: $supi-block-margin 0;

      &__table-cookie {
        box-shadow: none;
        border: 0;
      }

      &__table-overview {
        border: 0;
      }
    }
  }

  &__button-wrap {
    background: $main-navigation-border;

    button#supi__choose {
      background: var(--supi-color-allow-background);
      color: var(--supi-color-allow);
    }
  }
}
