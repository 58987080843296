@use 'sass:math';

#header {
  --header-height: 100px;
  --font-size: #{px-to-rem(16px)};
  --font-color: #{$copytext};
  --font-color-hover: #{$brand-primary};
  --border-color: #{$main-navigation-background};
  --nav-gap: #{px-to-rem(20px)};
  --nav-hover: flex;

  padding-block: $grid-gutter-width * 0.5;
  position: relative;
  z-index: 3;

  @include media-breakpoint-up(lg) {
    --header-height: 150px;
  }

  @include media-breakpoint-up(xl) {
    --header-height: 220px;
  }

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(md) {
      max-width: unset;
    }

    @include media-breakpoint-up(lg) {
      display: grid;
      row-gap: px-to-rem(32);
    }

    @include media-breakpoint-up(xl) {
      row-gap: px-to-rem(15);
    }

    &:after {
      content: unset;
    }

    &__logo {
      grid-row: 1 / 3;
      grid-column: 1;
      width: var(--header-height);

      @include media-breakpoint-up(md) {
        margin: 0 auto;
      }

      @include media-breakpoint-up(lg) {
        margin: 0;
      }
    }

    &__navigation {
      transition-property: height;
      transition-duration: 0.4s;
      transition-timing-function: ease-in-out;

      @include media-breakpoint-down(md) {
        --font-color: #{$main-background-color};

        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        display: none;
        overflow: hidden;
        background: rgba(26, 78, 143, 0.95);
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
      }

      @include media-breakpoint-up(lg) {
        display: contents;
      }

      &-toggle {
        background-color: transparent;
        border: none;
        padding: 0;
        width: 30px;
        height: 21px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: px-to-rem(6);

        &:focus {
          outline: unset;
        }

        &:before,
        .hamburger,
        &:after {
          width: 100%;
          height: 3px;
          background: #153c88;
          border-radius: 1px;
          transition-property: opacity, rotate, margin-top;
          transition-timing-function: ease-in-out;

          @include motion-safe() {
            transition-duration: 0.4s;
          }
        }

        &:before,
        &:after {
          content: '';
        }

        @include media-breakpoint-up(lg) {
          display: none;
        }

        &[aria-expanded='true'] {
          &:before {
            opacity: 0;
          }

          .hamburger {
            rotate: 45deg;
          }

          &:after {
            rotate: -45deg;
            margin-top: -8px;
          }
        }
      }

      nav {
        @include media-breakpoint-up(lg) {
          display: flex;
          justify-content: flex-end;
        }

        button,
        a {
          > * {
            pointer-events: none;
          }

          border: none;
          background: transparent;
          color: var(--font-color);
          font-size: var(--font-size);
          border-bottom: 1px solid transparent;
        }

        a {
          padding: px-to-rem(16) px-to-rem(30);

          &:hover {
            text-decoration: none;
          }

          @include media-breakpoint-up(lg) {
            padding: 0;

            &:hover {
              @include anchor-underline;
            }
          }
        }

        button {
          padding: px-to-rem(15);
          border-left: 1px solid var(--border-color);
          width: 57px;
          aspect-ratio: 1/1;

          &:focus {
            outline: unset;
          }

          &:focus-visible {
            @include tab-focus;
          }

          @include media-breakpoint-up(lg) {
            padding: 0;
            height: auto;
            border-left: none;
            aspect-ratio: unset;
            width: unset;
          }
        }

        &#main-navigation {
          grid-row: 2;
          grid-column: 1 / 3;

          @include media-breakpoint-up(lg) {
            --font-size: #{px-to-rem(20)};
          }
        }

        &#meta-navigation {
          --font-color: rgba(255, 255, 255, 0.75);
          --font-color-hover: var(--font-color);
          --font-size: #{px-to-rem(15)};
          --nav-hover: initial;

          grid-column: 2;
          grid-row: 1;

          @include media-breakpoint-up(lg) {
            --font-color: #{$link-color-content};
          }
        }
      }

      .navigation {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
          flex-direction: row;
          gap: $grid-gutter-width * 0.5;
        }

        &-item {
          &:has(.subnav.open) > div .subnav-toggle svg {
            rotate: 0deg;

            @include media-breakpoint-up(lg) {
              transform: translateY(5px);
            }
          }

          div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid var(--border-color);

            button {
              display: flex;
              align-items: center;
              justify-content: center;

              @include media-breakpoint-up(lg) {
                padding: px-to-rem(8);
              }

              svg {
                rotate: -90deg;
                width: 20px;
                height: 20px;
                transition-property: rotate, transform;
                transition-timing-function: ease-in-out;

                @include motion-safe() {
                  transition-duration: 0.4s;
                }

                @include media-breakpoint-up(lg) {
                  rotate: 0deg;
                  width: 12px;
                  height: 13px;
                }
              }
            }
          }
        }
      }

      .subnav {
        --font-color: #{$brand-light};

        flex-direction: column;
        margin: 0;
        list-style: none;
        display: none;
        padding: 0;
        background-color: hsla(0, 0%, 100%, 0.9);
        transition-property: height;
        transition-duration: 0.4s;
        transition-timing-function: ease-in-out;

        @include media-breakpoint-up(lg) {
          --font-color: #{$copytext};
          --font-size: 18px;

          background-color: $main-background-color;
          padding: px-to-rem(15) 0;
          position: absolute;
          left: px-to-rem(-30);
          top: 100%;
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);

          &.open {
            display: flex;
          }
        }

        li {
          &:has(.subnav.open) > div .subnav-toggle svg {
            @include media-breakpoint-down(md) {
              rotate: 0deg;
            }

            @include media-breakpoint-up(lg) {
              transform: translateY(5px);
            }
          }

          div {
            position: relative;
            --border-color: #{$brand-primary};

            @include media-breakpoint-up(lg) {
              margin: 0 px-to-rem(15);
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid $main-navigation-border;
            }

            a {
              line-height: normal;

              @include media-breakpoint-up(lg) {
                --font-color: #{$link-hover-color};

                padding: px-to-rem(7.5) px-to-rem(15);
                text-wrap: nowrap;
              }
            }

            button {
              --border-color: #{$brand-primary};

              @include media-breakpoint-up(lg) {
                --font-color: #{$link-hover-color};

                padding: px-to-rem(7.5) px-to-rem(15);
                display: flex;
                justify-content: center;
                align-items: center;
              }

              svg {
                rotate: -90deg;
                width: 20px;
                height: 20px;
                transition-property: rotate, transform;
                transition-timing-function: ease-in-out;

                @include motion-safe() {
                  transition-duration: 0.4s;
                }

                @include media-breakpoint-up(lg) {
                  width: 20px;
                  height: 16px;
                }
              }
            }
          }

          ul {
            @include media-breakpoint-up(lg) {
              width: max-content;
              left: 100%;
              top: 0;
            }

            li {
              a {
                @include media-breakpoint-down(md) {
                  padding-left: px-to-rem(45);
                }
              }
            }
          }
        }
      }
    }
  }
}

// @deprecated
body > header#header-legacy {
  min-height: $headerHeight;

  a.logo {
    display: inline-block;
    width: 359px;
    max-width: 100%;
    height: 108px;
    line-height: 108px;
  }

  nav {
    @include media-breakpoint-up(lg) {
      div.flex {
        display: flex !important;
        flex-direction: column;
      }
    }

    /* first */
    ul {
      &#main {
        position: relative;

        @include media-breakpoint-up(sm) {
          order: 2;
        }

        text-align: right;
        margin-top: 20px;

        a {
          @include reset-text();
          font-size: px-to-rem(20);
          line-height: normal;
          white-space: nowrap;
        }

        & > li {
          float: none;
          display: inline-block;
          position: relative;

          @include media-breakpoint-down(md) {
            display: block;
            text-align: center;
          }

          &:hover {
            a {
              position: relative;
              color: $link-hover-color;

              &:after {
                position: absolute;
                bottom: 0;
                left: 50%;
                content: '';
                width: 90%;
                margin-left: -45%;
                border-bottom: 1px solid $link-hover-color;
              }
            }

            & > ul {
              display: block;
            }
          }

          /* second */
          ul {
            display: none;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
            background: $main-navigation-background;

            @include media-breakpoint-up(lg) {
              position: absolute;
              z-index: 1;
              padding: math.div($grid-gutter-width, 2) 0;
              left: -$grid-gutter-width;
              text-align: left;
              min-width: 250px;
            }

            li {
              display: block;
              width: 100%;
              margin: 0;
              padding: 0 math.div($grid-gutter-width, 2);

              a {
                display: block;
                padding: math.div($grid-gutter-width, 4) math.div($grid-gutter-width, 2);
                font-size: px-to-rem(18);
                border-bottom: 1px solid $main-navigation-border;

                &:after {
                  display: none;
                }
              }

              &.has-sub {
                position: relative;

                &:after {
                  @include inline-svg($nav-arrow);
                  position: absolute;
                  right: 24px;
                  top: 50%;
                  content: ' ';
                  color: #fff;
                  padding-top: 10px;
                  margin-top: -#{math.div(inline-svg-height($nav-arrow), 2)};
                  text-align: center;
                  transform: scale(1.6);
                }

                /* third */
                & > ul {
                  display: none;
                  max-width: 220px;

                  position: absolute;
                  left: 100%;
                  top: 0;
                  padding-top: math.div($grid-gutter-width, 2);
                  background: $main-navigation-background;

                  li {
                    padding: 0 math.div($grid-gutter-width, 2);

                    a {
                      padding-left: math.div($grid-gutter-width, 4);
                      padding-right: math.div($grid-gutter-width, 4);
                      white-space: normal;
                      word-break: normal;
                    }
                  }
                }

                &:hover {
                  & > ul {
                    display: block;
                  }
                }
              }
            }
          }

          &:last-child ul {
            left: auto;
            right: -$grid-gutter-width;
          }
        }
      }

      &#meta {
        @include media-breakpoint-up(sm) {
          order: 1;
        }

        @include media-breakpoint-down(md) {
          li {
            display: block;
            text-align: center;
            margin: 0;
          }
        }

        width: 100%;
        text-align: right;
        // margin-top: $grid-gutter-width / 2;

        a {
          @include reset-text();
          color: $theme-color-1;
          font-size: px-to-rem(15);
          line-height: normal;
        }
      }
    }
  }
}

// default sujet image area for subpages
.s-subpagesujet {
  min-height: px-to-rem(200);
  overflow: hidden;
  @include media-breakpoint-down(md) {
    display: none !important;
  }

  background: {
    color: $subpage-sujet-background;
    size: cover;
    position: top center;
  }
}
