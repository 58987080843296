// Basic styles (Rahmenlayout)

:root {
  --container-grid-max-width: 100%;

  @each $breakpoint, $container-grid-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint) {
      --container-grid-max-width: #{$container-grid-max-width};
    }
  }
}
