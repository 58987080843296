@use 'sass:math';

/**
 * bootstrap fix for columns
 */
@each $breakpoint in map-keys($grid-breakpoints) {
  @for $i from 1 through $grid-columns {
    .col-#{$breakpoint}-#{$i} {
      position: relative;
      min-height: 1px;
      padding-right: math.div($grid-gutter-width, 2);
      padding-left: math.div($grid-gutter-width, 2);
    }
  }
}

/**
 * Colored area
 */
@mixin background-variant($backgroundcolor, $textcolor: $body-color, $linkcolor: $link-color, $link-color-hover: '') {
  background-color: $backgroundcolor;

  color: $textcolor;

  a {
    color: $linkcolor;

    @include hover-focus {
      @if ($link-color-hover != '') {
        color: $link-color-hover;
      } @else {
        color: darken($linkcolor, 15%);
      }
    }
  }
}

@mixin button($size: '') {
  display: inline-block;
  border-radius: 5px;
  padding: math.div($grid-gutter-width, 2) $grid-gutter-width;

  @if ($size == 'small') {
    padding: math.div($grid-gutter-width, 4) math.div($grid-gutter-width, 2);
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: math.div($grid-gutter-width, 2);
  }

  /*
	background
	color
	border color
	title replacement
	*/
}
/**
 * Font smoothing
 *
 * Usage:
 *    .dark-on-light {
 *   	@include font-smoothing(off);
 *   }
 *
 *   .light-on-dark {
 *   	@include font-smoothing(on);
 *   }
 */
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

@mixin motion-safe() {
  @media (prefers-reduced-motion: no-preference) {
    @content;
  }
}

@mixin anchor-underline {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}
