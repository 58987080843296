// General website footer
footer {
  // bigger font-size on larger devices
  // 		for specific areas of the website
  @include media-breakpoint-up(lg) {
    // font-size: $font-size-large-device;
    p {
      font-size: 1em;
    }
  }

  .footer {
    &-cta {
      @include background-variant($footer-cta-background, $brand-primary, $brand-primary, $brand-primary);
      > .row {
        > div {
          padding-top: px-to-rem(17);
          padding-bottom: px-to-rem(17);
        }
      }
      @include media-breakpoint-up(lg) {
        > .row {
          display: flex;
          > div {
            border-right: 1px solid white;
            &:last-child {
              border-right: 0;
            }
          }
        }
      }
    }
    &-sitemap {
      background-color: $footer-sitemap-background;
      color: $brand-primary;
      a {
        color: $footer-sitemap-linkcolor;
      }

      padding-top: px-to-rem(53);
      padding-bottom: px-to-rem(35);

      &-col {
        // display: flex;

        @include media-breakpoint-up(xl) {
          // display: block;
        }

        padding-top: px-to-rem(10);
      }

      > nav {
        // float:left;
        // margin-right:px-to-rem(30);

        @include media-breakpoint-up(xl) {
          margin-bottom: $grid-gutter-width;
        }
      }

      &-nav {
        padding-right: px-to-rem(20);
        &-sub {
        }
      }

      &-infocol {
        padding-top: px-to-rem(20);
        @include media-breakpoint-up(lg) {
          padding-top: 0;
        }
        a {
          color: $footer-sitemap-infocol-linkcolor;
          @include anchor-underline;
        }
      }
    }

    &-last {
      background: $main-background-color;
      padding-top: 45px;
      padding-bottom: 80px;
    }
  }
}
