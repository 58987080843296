.breadcrumb {
  &-item {
    font-size: px-to-rem(18);

    > a {
      color: $link-color-content;

      &:hover {
        @include anchor-underline;
      }
    }

    &.active {
      cursor: default;
      color: $link-color;
    }

    & + .breadcrumb-item::before {
      color: $link-color-content;
    }
  }
}
