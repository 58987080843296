$my-gutter: 32px;

$spacers: (
  0: 0,
  og: $my-gutter * 0.125,
  qg: $my-gutter * 0.25,
  sg: $my-gutter * 0.375,
  hg: $my-gutter * 0.5,
  qp: $my-gutter * 0.75,
  fg: $my-gutter,
  1h: $my-gutter * 1.5,
  dg: $my-gutter * 2,
);

@each $class,
  $attr
    in (
      m: margin,
      mb: margin-bottom,
      mt: margin-top,
      ml: margin-left,
      mr: margin-right,
      my: (
        margin-top,
        margin-bottom,
      ),
      mx: (
        margin-left,
        margin-right,
      ),
      p: padding,
      pb: padding-bottom,
      pt: padding-top,
      pl: padding-left,
      pr: padding-right,
      gap: gap,
      row-gap: row-gap,
      column-gap: column-gap
    )
{
  @each $spacerName, $spacerValue in $spacers {
    @each $bp, $width in $grid-breakpoints {
      @if ($bp == 'xs') {
        @each $attribute in $attr {
          @each $a in $attribute {
            .#{$class}-#{$spacerName} {
              #{$a}: #{$spacerValue};
            }
          }
        }
      } @else {
        @include media-breakpoint-up($bp) {
          @each $attribute in $attr {
            @each $a in $attribute {
              .#{$class}-#{$bp}-#{$spacerName} {
                #{$a}: #{$spacerValue} !important;
              }
            }
          }
        }
      }
    }
  }
}

@each $classNamePrefix,
  $values
    in (
      d: (
        display: (
          flex,
          none,
        ),
      ),
      flex: (
        flex-wrap: (
          wrap,
        ),
      ),
      position: (
        position: (
          relative,
          absolute,
        ),
      )
    )
{
  @each $property, $styles in $values {
    @each $style in $styles {
      .#{$classNamePrefix}-#{$style} {
        #{$property}: #{$style} !important;
      }
    }
  }
}

.stretched-link {
  &:before {
    position: absolute;
    content: '';
    inset: 0 ($my-gutter * 0.5);
  }
}

.frame-space {
  &-before {
    &-medium {
      margin-top: $grid-gutter-width;
    }

    &-large {
      margin-top: $grid-gutter-width * 2;
    }
  }

  &-after {
    &-medium {
      margin-bottom: $grid-gutter-width;
    }

    &-large {
      margin-bottom: $grid-gutter-width * 2;
    }
  }
}

.w-100 {
  width: 100% !important;
}

.arrow {
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid $brand-primary;
  border-right: 1px solid $brand-primary;
  transform: rotate(45deg);

  &_left {
    transform: rotate(-135deg);
  }
}

.s-header__cta {
  --cta-btn-bg: #{$main-background-color};

  & + .s-header__cta {
    --cta-btn-bg: #{$gray-light};
  }

  .btn {
    border-radius: 0;
    color: $brand-primary;

    &-white {
      background-color: var(--cta-btn-bg) !important;
    }
  }
}
