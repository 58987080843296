.ce-footer-cta {
  .block-link {
    margin-left: auto;
    margin-right: auto;
    &:hover,
    &:focus {
      text-decoration: none;
      .ce-footer-cta-header {
        text-decoration: underline;
      }
    }
    @include media-breakpoint-up(lg) {
      max-width: px-to-rem(270);
    }
    @include media-breakpoint-up(xl) {
      max-width: px-to-rem(310);
    }
  }
  &-header {
    margin-bottom: px-to-rem(5);
    color: inherit;
  }
  &-nav-image {
    height: px-to-rem(80);
    display: flex;
    flex-direction: row;
    justify-content: center;
    img {
      margin: auto;
    }
  }
}
