@import 'breakpoints';
@import 'container';
@import 'fonts';

/* colors */
$brand-primary: #003a87;
$brand-secondary: #a9a9a9;
$copytext: #000000;
// $brand-alternative: #143b88;
$brand-alternative: #003a87;
$brand-light: #1d4e8f;
$gray-light: #eeede1;
$link-color: #000;
$link-hover-color: $brand-primary;
$main-background-color: #fff;
$main-navigation-background: $main-background-color;
$main-navigation-border: #e3e3e3;
$content-alternative-background: #f5f6f0;

/* ce-header colors */
$ce-header-h1-color: $brand-primary;
$ce-header-link-border-color: $brand-primary;
$ce-header-link-bg-color: $brand-primary;
$ce-header-link-font-inverted-color: #fff;
$ce-header-link-font-color: $brand-primary;
$ce-header-cta-background: #e5e4d3;

/* theme color */
$theme-color-1: #78a3bf;
$theme-color-2: #aaaaa9;
$theme-color-3: #f6f5ef;
$ce-color-lead: $brand-primary;
$footer-cta-background: #c5d8e5;
$footer-sitemap-background: $theme-color-3;
$footer-sitemap-linkcolor: $brand-primary;
$footer-sitemap-infocol-linkcolor: $brand-primary;
$button-color-light: #154f93;
$button-color-primary: $brand-primary;
$link-color-content: $brand-primary;
$subpage-sujet-background: $theme-color-1;

$table-thead: #eaeced;

/* fonts */
$font-family-source: 'Source Sans Pro', sans-serif;
$font-family-arimo: 'Arimo', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;
$font-family-catamaran: 'Catamaran', sans-serif;

$font-family-sans-serif: $font-family-source;

$font-size-h1: px-to-rem(40);
$font-size-h2: px-to-rem(35);
$font-size-h3: px-to-rem(26);

$lead-font-size: px-to-rem(30);
$lead-line-height: px-to-rem(38);

/* font awesome icons */
$fa-font-path: '//netdna.bootstrapcdn.com/font-awesome/4.6.3/fonts';

/* other stuff */
$headerHeight: 108px;
$logobarHeight: 102px;
$mobileNavHeight: 50px;

/* typography */
$font-size-large-device: 18px;
$body-color: #030400;

/* Breadcrumbs */
$breadcrumb-padding-y: px-to-rem(23);
$breadcrumb-padding-x: px-to-rem(0);
$breadcrumb-item-padding: px-to-rem(10);

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $theme-color-1;
$breadcrumb-active-color: $theme-color-1;
$breadcrumb-divider: '|';
