.s-content__teaser {
  h2 {
    line-height: px-to-rem(50);
    font-style: px-to-rem(40);
    color: $brand-primary;
    margin-bottom: 0;

    & + p {
      margin-top: $grid-gutter-width;
    }
  }

  p {
    font-size: px-to-rem(30);
    line-height: px-to-rem(35);
    color: $brand-primary;

    &:last-of-type {
      margin-bottom: 0;
    }

    & + .s-content__teaser-buttons {
      margin-top: $grid-gutter-width;

      a {
        @include reset-text();
        @include button();
        color: $brand-primary;

        &:first-child {
          background: $brand-primary;
          color: #fff;
        }

        .alternative & {
          color: $brand-primary;
          background: none;

          &:first-child {
            background: #fff;
            color: $button-color-light;
          }
        }
      }
    }

    .alternative & {
      color: $brand-primary;
    }
  }
}
