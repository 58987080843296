@use 'sass:math';

.card {
  opacity: 0.5;

  .card-block {
    padding: math.div($grid-gutter-width, 2) 0;
    font-size: 16px;

    .card-title {
      @extend .col-xs-12;
      font-size: 1.2em;
      font-weight: 100;
    }

    .card-text {
      @extend .col-xs-12;

      dl {
        margin-bottom: math.div($grid-gutter-width, 2);
        line-height: 1;
      }

      dd,
      dt {
        font-weight: 100;
      }
    }
  }

  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 1;
  }

  aside & {
    margin-top: $grid-gutter-width;
  }
}
