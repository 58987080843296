@use 'sass:math';

/* team fe layout */
.fe-layout-5 {
  @include make-row;
  display: flex;
  flex-wrap: wrap;

  /*
	& > div {
		@extend .col-md-6;

		margin-bottom: $grid-gutter-width;

		h3 {
			margin-top: 0;
		}
	}
	*/

  /* new team content element */
  .s7-team {
    display: flex;
    margin-bottom: $grid-gutter-width;
    min-height: 250px;

    & > div {
      background: rgba($brand-secondary, 0.1);
      padding: math.div($grid-gutter-width, 2);
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    img {
      border-radius: 100%;
      max-width: 100%;
      height: auto;
    }

    small {
      font-size: 0.7rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    h3,
    span {
      margin: 0;
    }

    h3 {
      font-weight: 600;
      font-size: 1.4rem;

      a {
        font-weight: 600;
        text-decoration: none;
        color: #000;
        font-size: 1.4rem;
      }
    }

    span {
      font-size: 0.875rem;
      margin: 5px 0;
      font-weight: 300;
    }

    address {
      font-size: 1rem;
      line-height: 1.5;
      position: relative;
      margin: math.div($grid-gutter-width, 2) 0 0 0;

      span {
        display: block;
        border-top: 1px solid #fff;
        font-size: 0.875rem;
        padding-top: math.div($grid-gutter-width, 2);
      }
    }

    .s7-team__contact {
      font-size: 1rem;
      line-height: 1.5;

      a {
        display: block;
        white-space: nowrap;

        /*
				position: relative;
				padding-left: 20px;

				&.ico {
					&:before {
						@include fa-icon();
						content: $fa-var-phone;
						position: absolute;
						left: 0;
						top: 5px;
						color: $brand-primary;
						font-size: 12px;
					}

					&.mail:before {
						content: $fa-var-envelope-o;
					}

					&.www:before {
						content: $fa-var-external-link;
					}
				}
				*/
      }
    }

    .row + .row {
      margin-top: math.div($grid-gutter-width, 2);
      align-self: flex-end;
      width: 100%;
    }
  }
}
