@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

$download: 'data:image/svg+xml,%3Csvg%20id%3D%22Ebene_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20137.6%20166.1%22%3E%3Cstyle%3E.st0%7Bfill%3A%23a0c1d6%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M115.2%20161.2H23.8c-8%200-14.5-6.5-14.5-14.5V20.9c0-8%206.5-14.5%2014.5-14.5h45.7c.5%200%202.4%200%203.8.7%201.4.6%202.7%201.9%202.9%202.1l51.6%2051.6c.1.1%201%201%201.5%202.3.3%201%20.4%202%20.4%202.1V146.6c0%208.1-6.6%2014.6-14.5%2014.6zM23.8%2011.4c-5.2%200-9.5%204.3-9.5%209.5v125.8c0%205.2%204.3%209.5%209.5%209.5h91.3c5.2%200%209.5-4.3%209.5-9.5V65.5c0-.2-.1-.5-.2-.9%200-.1-.2-.3-.2-.3L72.7%2012.8c-.3-.3-1.1-1-1.5-1.2-.2-.1-1-.2-1.5-.2H23.8z%22%2F%3E%3C%2Fsvg%3E'
  137px 166px;
$nav-arrow: 'data:image/svg+xml,%3Csvg%20version%3D%221%22%20id%3D%22Ebene_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%207.1%2010.5%22%3E%3Cstyle%3E.st0%7Bfill%3A%237090ba%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M1.7%209.8l4.5-4.3c.1-.1.1-.3.1-.4%200-.2%200-.3-.1-.4L1.7.4C1.6.3%201.5.3%201.4.3c-.1%200-.2%200-.3.1C1%20.5.9.7.9.8c0%20.1.1.2.2.3l4.3%204-4.2%204c-.2.2-.2.5%200%20.6.1.2.4.2.5.1z%22%2F%3E%3C%2Fsvg%3E'
  7px 10px;

@mixin inline-svg($name, $color: null) {
  @if ($color) {
    $color: str-replace($color + '', '#', '');
    background: transparent url(str-replace(nth($name, 1), '%23FFF', '%23' + $color)) no-repeat 50% 50%;
    color: $color;
  } @else {
    background: transparent url(nth($name, 1)) no-repeat 50% 50%;
  }
  background-size: 100%;
  width: nth($name, 2);
  height: nth($name, 3);
}

@function inline-svg-width($name) {
  @return nth($name, 2);
}

@function inline-svg-height($name) {
  @return nth($name, 3);
}
