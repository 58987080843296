@use 'sass:math';

.ce-gallery {
  .ce-outer,
  .ce-inner,
  .ce-column {
    float: none;
    right: auto;
  }

  .ce-inner {
    @include make-row();

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .ce-row {
      @extend .col-md-4;

      figure {
        background: rgba(0, 0, 0, 0.02);
        display: block;

        img {
          max-width: 100%;
          height: auto;
          margin: 0 auto;
        }

        figcaption {
          display: block;
          font-size: 0.75rem;
          text-align: center;
          background: #fff;
          padding: math.div($grid-gutter-width, 4) 0;
        }
      }
    }
  }
  figure {
    margin-bottom: px-to-rem(16);
    img {
      @include img-fluid();
    }
  }
}

.ce-intext.ce-right .ce-gallery,
.ce-intext.ce-left .ce-gallery,
.ce-above .ce-gallery {
  margin-bottom: 10px;
}
