.tmpl-default,
.tmpl-defaultnosidebar {
  .content {
    &-main {
      a {
        color: $link-color-content;
        @include anchor-underline;
      }
      p:not(#lead) {
        // general margin bottom
        margin-bottom: px-to-rem(16);
      }
    }
    &-sidebar {
      a {
        color: $link-color-content;
        &:hover {
          @include anchor-underline;
        }
      }
      p:not(#lead) {
        // general margin bottom
        margin-bottom: px-to-rem(16);
      }
    }
    &-main {
      font-size: px-to-rem(18);

      @include media-breakpoint-up(lg) {
        margin-bottom: px-to-rem(90);
      }
      @include media-breakpoint-down(md) {
        margin-bottom: px-to-rem(30);
      }
    }
    &-sidebar {
      @include media-breakpoint-down(md) {
        margin-bottom: px-to-rem(30);
      }
    }
  }
}
