.CE_YOUTUBE_ITEM {
  margin-bottom: 44px;
  position: relative;

  img {
  }

  .CE_YOUTUBE_GDPR_OVERLAY {
    position: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    background-color: rgba($brand-primary, 0.3);
  }

  @include media-breakpoint-up(sm) {
    .CE_YOUTUBE_GDPR_OVERLAY {
      position: absolute;
      top: 20px;
      bottom: 20px;
      left: 20px;
      right: 20px;
      background-color: rgba(255, 255, 255, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        max-width: 60%;
      }
    }
  }

  a.btn-primary {
    color: white;
  }
}

.video-embed {
  @extend .embed-responsive, .embed-responsive-16by9;

  .video-embed-item {
    @extend .embed-responsive-item;
  }
}
