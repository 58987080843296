@use 'sass:math';

.s-content {
  padding-top: $grid-gutter-width * 2;
  padding-bottom: $grid-gutter-width * 2;

  h2 {
    color: $brand-primary;
  }

  &.alternative {
    background: $content-alternative-background;
  }
}

// content images
.ce-media {
  margin-bottom: px-to-rem(16);
  img {
    @include img-fluid();
  }
}

// ordered list
.content-main ol {
  padding: 0;
  counter-reset: item;
  > li {
    padding: 0 0 0 px-to-rem(40);
    text-indent: -1.5em;
    list-style-type: none;
    counter-increment: item;
    &:before {
      display: inline-block;
      width: 1.5em;
      padding-right: 0.5em;
      font-weight: 600;
      text-align: right;
      content: counter(item) '.';
    }
  }
}

// table styling
table {
  border: 1px solid $table-thead;
  thead {
    td {
      background-color: $table-thead;
      font-weight: 600;

      border-right: 1px solid #fff;
      &:last-child {
        border-right: none;
      }
    }
  }
  tbody {
    td {
      border-right: 1px solid $table-thead;
      &:last-child {
        border-right: none;
      }
    }
  }
}

/* lists */
ul {
  &.normal-list {
    padding: 0;
    margin: math.div($grid-gutter-width, 2) 0;

    li {
      list-style: none;
      position: relative;
      padding-left: math.div($grid-gutter-width, 1.5);

      &:before {
        content: '•';
        color: $theme-color-1;
        position: absolute;
        left: 5px;
        top: -4px;
        font-weight: 600;
        font-size: 24px;
      }
    }
  }
}

// file downloads
.ce-uploads {
  margin: 0 0 px-to-rem(16);
  padding: 0;

  li {
    margin: 0;
    list-style: none outside none;
  }

  &-item {
    &-type-0 {
      margin: 0;
    }
  }

  &-fileName {
    display: block;
  }

  &-description {
    display: block;
  }
}

span.ce-uploads-icon {
  @include inline-svg($download);
  width: 31px;
  height: 40px;
  float: left;
  margin-right: px-to-rem(25);
  display: flex;
  align-items: center;
  justify-content: center;

  &-type {
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    @include font-smoothing();
    align-self: flex-end;
    margin-bottom: px-to-rem(2);
  }
}

.content-sidebar {
  h2,
  h3,
  p {
    color: $brand-primary;
  }
  h2,
  h3 {
    font-size: px-to-rem(16);
    font-weight: 600;
    margin-bottom: px-to-rem(10);
  }
  p {
    margin-bottom: px-to-rem(30);
  }
  .ce-uploads-item-type-1 {
    margin-bottom: px-to-rem(5);
  }
  .ce-uploads-filesize {
    font-size: px-to-rem(13);
    margin-bottom: px-to-rem(3);
  }
  .ce-uploads-fileName {
    word-wrap: break-word;
  }
  .ce-uploads-description {
    word-wrap: break-word;
  }
}

/* input fields */
form {
  [type='checkbox'] {
    margin-right: math.div($grid-gutter-width, 4);
  }
}
