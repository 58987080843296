@use 'sass:math';

.s-faq {
  & > ul {
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
      padding: 0;
      margin: 0;
    }
  }

  ul ul {
    li {
      margin: math.div($grid-gutter-width, 2) 0;

      &:before {
        top: -7px;
      }
    }

    h4 {
      font-weight: 100;
      font-size: px-to-rem(18);

      a {
      }
    }

    div {
      display: none;
    }
  }
}
