@use 'sass:math';

.s-header {
  --full-width-grid-margin: calc(var(--bs-gutter-x) * 0.5);
  --template-rows: auto auto;
  --bs-gutter-x: 2rem;

  font-size: 16px;
  background-image: url('../Images/sujet-default01.png');
  background-size: cover;
  display: grid;
  grid-template-columns: minmax(var(--full-width-grid-margin), 1fr) minmax(0, var(--container-grid-max-width)) minmax(
      var(--full-width-grid-margin),
      1fr
    );
  grid-template-rows: var(--template-rows);

  @include media-breakpoint-up(md) {
    --bs-gutter-x: 3rem;
  }

  @include media-breakpoint-up(lg) {
    --bs-gutter-x: 4rem;
  }

  &:after {
    --grid-column: 1 / -1;

    content: '';
    position: relative;
    grid-row: 1;
    grid-column: var(--grid-column);
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(5px);
    z-index: 1;

    @include media-breakpoint-up(lg) {
      --grid-column: 2;
      --width: #{percentage(math.div(8, 12))};
      width: var(--width);
    }

    @include media-breakpoint-up(xl) {
      --width: #{percentage(math.div(7, 12))};
    }
  }

  &__content {
    position: relative;
    grid-column: 1 / 4;
    grid-row: 1 / -1;
    z-index: 2;
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;

    article {
      --column: 2 / 3;
      --padding: #{$grid-gutter-width} 0 #{$grid-gutter-width * 2};

      grid-row: 1;
      grid-column: var(--column);
      padding: var(--padding);

      @include media-breakpoint-up(lg) {
        --column: 2 / 3;
        --padding: #{$grid-gutter-width} #{$grid-gutter-width * 0.5} #{$grid-gutter-width * 2} #{$grid-gutter-width};

        width: percentage(math.div(7, 12));
      }

      @include media-breakpoint-up(xl) {
        --padding: #{$grid-gutter-width * 2} #{$grid-gutter-width * 0.5} #{$grid-gutter-width * 2} #{$grid-gutter-width};

        display: flex;
        align-items: center;
      }
    }

    aside {
      --row: 2;
      --column: 1 / 4;

      grid-row: var(--row);
      grid-column: var(--column);

      @include media-breakpoint-up(lg) {
        --row: 1;
        --column: 2 / 3;
        --padding: #{$grid-gutter-width * 1.25} 0;

        justify-self: end;
        width: percentage(math.div(5, 12));
        padding: var(--padding);
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
      }

      @include media-breakpoint-up(xl) {
        --padding: 0 #{$grid-gutter-width};
      }
    }
  }

  &__intro {
    --heading-size: #{px-to-rem(37.5)};
    --text-size: #{px-to-rem(22)};

    @include media-breakpoint-up(md) {
      --heading-size: #{px-to-rem(46)};
      --text-size: #{px-to-rem(30)};
    }

    h1 {
      color: $ce-header-h1-color;
      font-size: var(--heading-size);
      text-shadow: 0 0 100px rgba(0, 0, 0, 0.3);
      hyphens: auto;
      hyphenate-limit-chars: 20;
      line-height: 1.2;
    }

    p {
      font-size: var(--text-size);
      line-height: 1.2;
      color: $brand-primary;
    }

    .s-header__intro-buttons {
      --flex-direction: column;

      margin-top: $grid-gutter-width;
      flex-direction: var(--flex-direction);
      align-items: start;
      gap: $grid-gutter-width * 0.5;

      @include media-breakpoint-up(sm) {
        --flex-direction: row;
      }

      a {
        --padding: #{$grid-gutter-width * 0.5};

        @include reset-text();
        display: inline-block;
        border-radius: 5px;
        border: 1px solid $ce-header-link-border-color;
        color: $button-color-primary;
        font-size: px-to-rem(18);
        margin: 0;
        padding: var(--padding);

        &:first-child {
          background: $ce-header-link-bg-color;
          color: $ce-header-link-font-inverted-color;
        }

        &:nth-child(2) {
          background: $ce-header-link-font-inverted-color;
        }

        @include media-breakpoint-up(md) {
          --padding: #{$grid-gutter-width * 0.5} #{$grid-gutter-width};
        }
      }
    }
  }

  &__cta {
    --flex-direction: column;

    background: $ce-header-cta-background;

    font-size: 16px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: var(--flex-direction);
    justify-content: center;
    align-items: center;

    @include media-breakpoint-up(md) {
      --flex-direction: row;
    }

    @include media-breakpoint-up(lg) {
      --align-self: center;
      --justify-self: center;
      --flex-direction: column;

      align-self: var(--align-self);
      min-height: 287px;
      min-width: 287px;
      max-width: 290px;
      grid-column: 1;
      grid-row: 1;
      aspect-ratio: 1 / 1;
      border-radius: 100%;
      transform: rotate(12deg);
      box-shadow: 10px 0 10px rgba(0, 0, 0, 0.4);
      justify-self: var(--justify-self);
      margin-bottom: $grid-gutter-width * 3;

      &:has(+ .s-header__cta) {
        --justify-self: end;
        --align-self: start;
      }
    }

    @include media-breakpoint-up(xl) {
      --align-self: center;

      &:has(+ .s-header__cta) {
        --align-self: center;
      }
    }

    h2 {
      --font-size: #{px-to-rem(22)};
      text-align: center;
      font-size: var(--font-size);
      hyphens: auto;
      color: $brand-light;

      @include media-breakpoint-up(lg) {
        --font-size: #{px-to-rem(35)};
      }
    }

    p {
      text-align: center;
      hyphens: auto;
      font-size: px-to-rem(16);

      & + a {
        @include reset-text();
        @include button('small');
        border-radius: 3px;
        background: #fff;
        color: $button-color-light;
        font-size: px-to-rem(18);
        font-weight: 100;
        margin-bottom: 0 !important;

        & + a {
          display: block;
          color: $brand-primary;
          text-decoration: underline;
        }
      }
    }

    & + .s-header__cta {
      background-color: $main-background-color;
      --align-self: start;

      @include media-breakpoint-up(lg) {
        --align-self: end;
        --justify-self: start;

        min-width: 220px;
        min-height: 220px;
        max-width: 250px;
        margin-bottom: $grid-gutter-width;
      }
    }
  }
}
