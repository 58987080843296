.pagination {
  background: $theme-color-3;
  border-radius: 0;

  .page-link.page-link {
    border-radius: 0;
    border-color: $brand-primary;
    background: transparent;

    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-decoration: none;

    &.active {
      color: $main-background-color;
      background: $brand-primary;
    }

    &:hover,
    &:focus {
      color: $main-background-color;
      background: $brand-primary;
      text-decoration: none;

      .arrow {
        border-color: $main-background-color;
      }
    }
  }
}
