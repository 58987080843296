@use 'sass:math';

@media print {
  * {
    position: relative;
  }

  #header,
  .s-header,
  .s-logobar,
  .mobile-nav,
  .read-all,
  .devbox,
  footer {
    display: none;
  }

  .print {
    display: block !important;
    margin: $grid-gutter-width auto;
  }

  .s-content {
    border-bottom: 1px solid #000;
    padding-top: math.div($grid-gutter-width, 2);
    padding-bottom: math.div($grid-gutter-width, 2);
  }

  h3 {
    a[href]:before {
      display: none;
    }
  }

  a {
    &[href]:before {
      content: '<http://www.mtd-austria.at' attr(href) '>';
      font-size: 9px !important;
      font-style: italic;
      color: #ccc;
      display: block;
    }

    &[href*='http']:before {
      content: '<' attr(href) '>';
    }
  }

  .s-content__news-item {
    border-bottom: 1px solid #ccc;

    &:last-child {
      border: none;
    }
  }
}
